import * as actionTypes from './actionTypes';

export const fetchClientList = (params) => ({
  type: actionTypes.FETCH_CLIENT_LIST,
  params,
});

export const fetchClientListSuccess = (data) => ({
  type: actionTypes.FETCH_CLIENT_LIST_SUCCESS,
  data,
});

export const fetchBestClients = (params, header) => ({
  type: actionTypes.FETCH_BEST_CLIENTS,
  params,
  header,
});

export const fetchClient = (id) => ({
  type: actionTypes.FETCH_CLIENT,
  id,
});

export const fetchClientSuccess = (data) => ({
  type: actionTypes.FETCH_CLIENT_SUCCESS,
  data,
});

export const resetClientPassword = (userId) => ({
  type: actionTypes.RESET_CLIENT_PASSWORD,
  userId,
});

export const changeClientEmail = (userId, data) => ({
  type: actionTypes.CHANGE_CLIENT_EMAIL,
  userId,
  data,
});

export const changeClientEmailWithoutUser = (userId, data) => ({
  type: actionTypes.CHANGE_CLIENT_EMAIL_WITHOUT_USER,
  userId,
  data,
});

export const updateClientProfile = (clientId, data) => ({
  type: actionTypes.UPDATE_CLIENT_PROFILE,
  clientId,
  data,
});

export const activateClient = (userId, clientId) => ({
  type: actionTypes.ACTIVATE_CLIENT,
  userId,
  clientId,
});

export const deactivateClient = (userId, clientId) => ({
  type: actionTypes.DEACTIVATE_CLIENT,
  userId,
  clientId,
});

export const activateCard = (cardId, clientId) => ({
  type: actionTypes.ACTIVATE_CARD,
  cardId,
  clientId,
});

export const deactivateCard = (cardId, clientId) => ({
  type: actionTypes.DEACTIVATE_CARD,
  cardId,
  clientId,
});

export const fetchBalanceHistory = (accountId, page) => ({
  type: actionTypes.FETCH_BALANCE_HISTORY,
  accountId,
  page,
});

export const fetchBalanceHistorySuccess = (accountId, data) => ({
  type: actionTypes.FETCH_BALANCE_HISTORY_SUCCESS,
  accountId,
  data,
});

export const updateBalance = (accountId, clientId, data) => ({
  type: actionTypes.UPDATE_BALANCE,
  accountId,
  clientId,
  data,
});

export const exportPersonalData = (clientId) => ({
  type: actionTypes.EXPORT_PERSONAL_DATA,
  clientId,
});

export const exportPersonalDataSuccess = (data) => ({
  type: actionTypes.EXPORT_PERSONAL_DATA_SUCCESS,
  data,
});

export const exportPersonalDataFailure = (error) => ({
  type: actionTypes.EXPORT_PERSONAL_DATA_FAILURE,
  error,
});

export const removePersonalData = (clientId) => ({
  type: actionTypes.REMOVE_PERSONAL_DATA,
  clientId,
});

export const removePersonalDataSuccess = (data) => ({
  type: actionTypes.REMOVE_PERSONAL_DATA_SUCCESS,
  data,
});

export const removePersonalDataFailure = (error) => ({
  type: actionTypes.REMOVE_PERSONAL_DATA_FAILURE,
  error,
});

export const uploadCardsFile = (file) => ({
  type: actionTypes.UPLOAD_CARDS_FILE,
  file,
});

export const getExpiredBalance = (accountId) => ({
  type: actionTypes.GET_EXPIRED_BALANCE,
  accountId,
});

export const getExpiredBalanceSuccess = (accountId, data) => ({
  type: actionTypes.GET_EXPIRED_BALANCE_SUCCESS,
  data,
  accountId,
});

export const subtractExpiredBonuses = (stores) => ({
  type: actionTypes.SUBTRACT_EXPIRED_BONUSES,
  stores,
});
