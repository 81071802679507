import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import paths from 'app/paths';
import { useAction } from 'app/hooks';
import { selectors as authSelectors } from 'ducks/auth';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { EditLinkButton } from 'components';
import { GenerateReportButton } from 'components/stores';
import { REPORT_TYPES } from 'components/stores/modals/GenerateReportModal';
import EuroIcon from '@material-ui/icons/Euro';
import UploadCardsFileButton from '../buttons/UploadCardsFileButton';
import SubtractExpiredBonusesButton from '../buttons/SubtractExpiredBonusesButton';

const StoreActionsCell = memo((props) => {
  const {
    item: { id, number },
  } = props;
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const showModal = useAction(modalsActions.showModal);

  const showGenerateReportModal = useCallback(() => {
    showModal(modalTypes.GENERATE_REPORT_MODAL, {
      storeNumber: number,
      reportType: REPORT_TYPES.DAILY,
    });
  }, [id, showModal]);

  const showUploadCardsFileModal = useCallback(() => {
    showModal(modalTypes.UPLOAD_CARDS_FOR_STORE_MODAL, { number });
  }, [number, showModal]);

  const showSubtractExpiredBonusesForStoreModal = useCallback(() => {
    showModal(modalTypes.SUBTRACT_EXPIRED_BONUSES_FOR_STORE_MODAL, { number });
  }, [number, showModal]);

  return (
    <>
      <EditLinkButton to={paths.storesEdit.path} params={{ id }} />
      {isSuperAdmin && <GenerateReportButton onClick={showGenerateReportModal} />}
      {isSuperAdmin && <UploadCardsFileButton onClick={showUploadCardsFileModal} />}
      {isSuperAdmin && (
        <SubtractExpiredBonusesButton
          className={EuroIcon}
          onClick={showSubtractExpiredBonusesForStoreModal}
        />
      )}
    </>
  );
});

StoreActionsCell.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
  }).isRequired,
};

export default StoreActionsCell;
