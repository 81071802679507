export default {
  app: {
    appName: 'G20',
    adminInterface: 'Admin Interface',
    main: 'Main',
    ok: 'Ok',
    cancel: 'Cancel',
    success: 'Success',
    error: 'Error',
    confirmation: 'Confirmation',
    confirmationQuestion: 'Are you sure you want to perform this action?',
    required: 'Required',
    first: 'First',
    last: 'Last',
    previous: 'Previous',
    next: 'Next',
    id: 'ID',
    invalidId: 'Invalid ID',
    selected: 'Selected',
    username: 'Username',
    none: 'None',
    action: 'Action',
    actions: 'Actions',
    search: 'Search',
    view: 'View',
    save: 'Save',
    submit: 'Submit',
    add: 'Add',
    added: 'Successfully added',
    cannotAdd: 'Cannot add',
    create: 'Create',
    created: 'Successfully created',
    cannotCreate: 'Cannot create',
    edit: 'Edit',
    updated: 'Successfully updated',
    uploaded: 'File successfully uploaded',
    cannotUpdate: 'Cannot update',
    cannotUpload: 'Cannot upload file',
    remove: 'Delete',
    removeAll: 'Delete all',
    removed: 'Successfully deleted',
    cannotRemove: 'Cannot delete',
    activate: 'Activate',
    activated: 'Successfully activated',
    cannotActivate: 'Cannot activate',
    deactivate: 'Deactivate',
    deactivated: 'Successfully deactivated',
    cannotDeactivate: 'Cannot deactivate',
    somethingWentWrong: 'Something went wrong',
    number: 'Number',
    invalidNumber: 'Invalid number',
    name: 'Name',
    contact: 'Contact',
    phoneNumber: 'Phone number',
    invalidPhoneNumber: 'Invalid phone number',
    address: 'Address',
    houseNumber: 'House number',
    street: 'Street',
    postalCode: 'Postal code',
    invalidPostalCode: 'Invalid postal code',
    city: 'City',
    country: 'Country',
    startDate: 'Start date',
    endDate: 'End date',
    invalidDate: 'Invalid date',
    moreThan: 'Value must be greater than {{more}}',
    notLessThanStartDate: 'Must be not less than start date',
    information: 'Information',
    firstName: 'First name',
    lastName: 'Last name',
    label: 'Label',
    lastModification: 'Last modification',
    general: 'General',
    noOptions: 'No options',
    date: 'Date',
    noData: 'No data',
    amount: 'Amount',
    invalidAmount: 'Invalid amount',
    quantity: 'Quantity',
    total: 'Total',
    yes: 'Yes',
    no: 'No',
    na: 'N/A',
    description: 'Description',
    actionIsNotReversible: 'Warning: this action is not reversible!',
    creationDate: 'Creation date',
    enabled: 'Enabled',
    disabled: 'Disabled',
    showMore: 'Show more',
    restored: 'Successfully restored',
    restore: 'Restore',
    startMonth: 'Start month',
    endMonth: 'End month',
    endMonthOptional: 'End month (optional)',
    endDateOptional: 'End month (optional)',
  },
  auth: {
    roles: {
      ROLE_SUPER_ADMIN: 'Super Admin',
      ROLE_G20_ADMIN: 'G20 Admin',
      ROLE_STORE: 'Store Admin',
    },
    role: 'Role',
    logIn: 'Log in',
    logOut: 'Log out',
    email: 'Email',
    changeEmail: 'Change email',
    emailChanged: 'Email has been changed',
    cannotChangeEmail: 'Cannot change email',
    password: 'Password',
    passwordConfirmation: 'Password confirmation',
    changePassword: 'Change password',
    resetPassword: 'Reset password',
    passwordReset: 'Password has been reset',
    cannotResetPassword: 'Cannot reset password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    newPasswordConfirmation: 'New password confirmation',
    passwordRequirements:
      'Password must contain an uppercase letter, a lowercase letter, a digit and be at least 8 characters',
    passwordsDoNotMatch: 'Passwords do not match',
    passwordChanged: 'Password has been changed',
    invalidEmail: 'Invalid email',
    invalidToken: 'Invalid token',
    invalidCredentials: 'Invalid username and password combination',
    invalidCurrentPassword: 'Invalid current password',
    emailSent: 'Email has been sent',
    exportPersonalData: 'Export personal data',
    removePersonalData: 'Delete personal data',
  },
  admins: {
    admins: 'Administrators',
    addAdmin: 'Add administrator',
    editAdmin: 'Edit administrator',
    group: 'Group',
    groups: 'Groups',
    locked: 'Locked',
  },
  stores: {
    startAtShouldBeGreaterThanCurrentDate: "'Start at' should be greater than current day!",
    loyaltyDisablePeriods: 'Loyalty disable periods',
    periodsShouldNotOverlap: "Periods shouldn't overlap",
    startAtIsRequired: "'Start at' is required!",
    endAtIsRequired: "'End at' is required!",
    endAtGreaterThanStartAt: "'End At' should be greater than 'Start At'",
    endAt: 'End at',
    startAt: 'Start at',
    addPeriod: 'Add period',
    store: 'Store',
    stores: 'Stores',
    priceGroup: 'Price group',
    exportOnlinePercentage: 'Export online percentage statistic',
    editStore: 'Edit store',
    addStore: 'Add store',
    storeNumber: 'Store number',
    invalidStoreNumber: 'Invalid store number',
    posNumber: 'Pos number',
    generateReport: 'Generate report',
    reportGenerated: 'Report generated',
    cannotGenerateReport: 'Cannot generate report',
    reporting: 'Reporting',
    status: 'Store status',
    currentStatus: 'Current status',
    reportingList: 'Reporting Enabled',
    dailyReport: 'Daily report',
    monthlyReport: 'Monthly report',
    reportRequestSent: 'Request to generate report sent',
    posAmount: 'Number of POS',
    invalidPosAmount: 'Number of POS should not be less than {{value}}',
    groupHaveAdmin: 'The selected group already has admins.',
    balancesSubtracted: 'Bonuses subtracted',
  },
  campaigns: {
    campaigns: 'Campaigns',
    campaignName: 'Campaign name',
    campaignDescription: 'Campaign description',
    create: 'Create new campaign',
    rules: 'Rules',
    promotion: 'Promotion',
    promotionCreate: 'Create promotion',
    promotions: 'Promotions',
    promotionDetails: 'Promotion details',
    promotionName: 'Promotion name',
    promotionId: 'Promotion ID',
    invalidPromotionId: 'Invalid promotion ID',
    promotionCount: 'Number of promotions',
    uploadSpecialCampaignFile: 'Upload special campaign file',
    uploadButton: 'Upload',
    promotionRuleTypes: {
      has_account: 'Must have loyalty account',
      cart_has_items: {
        all: '{{threshold}} times all articles',
        count: 'At least {{threshold}} article of the list',
        one_of: 'Basket must contain at least {{threshold}} times one of the articles in the list',
      },
      item_group_total: 'The total of products list in the cart reaches {{amount}}',
      per_card_usage_limit: 'Usage limit per card is {{limit}} times',
      per_plig_usage_limit: 'Usage limit per PLIG',
      items_have_reference_price: 'Article price is equal or below reference price',
      order_total: 'Cart total without any discounts is greater or equals to {{amount}} €',
      per_card_discount_limit: 'Total discount limit per card: {{limit}} €',
    },
    promotionRuleTypesBrief: {
      per_card_usage_limit: 'Usage limit per card',
      order_total: 'Cart total without any discounts',
      per_card_discount_limit: 'Total discount limit per card',
    },
    target: 'Target',
    promotionTargetTypes: {
      cardholders: 'Cardholders',
      non_cardholders: 'Non cardholders',
      all_clients: 'Cardholders and non cardholders',
    },
    cartHasItems: {
      method: 'Method',
      threshold: 'Threshold',
      all: 'All',
      count: 'Count',
      one_of: 'One of',
    },
    removePligsList: 'Remove pligs list ({{n}})',
    minPligs: 'Pligs list should contain at least 1 item',
    addSecondPligList: 'Add second pligs list',
    limit: 'Per ticket limit',
    limitPerCard: 'Per card limit',
    limitPerCardEuro: 'Per card limit (€)',
    cycle: 'Cycle',
    addRule: 'Add rule',
    removeRule: 'Remove rule',
    discount: 'Discount',
    discounts: 'Discounts',
    discountType: 'Discount type',
    delayed: 'Delayed',
    immediate: 'Immediate',
    immediateDiscounts: 'Immediate Discounts',
    fixedDiscount: 'Fixed discount',
    fixedTotalDiscount: 'Fixed total discount',
    percentageDiscount: 'Percentage discount',
    finDiscount: 'First item neutral discount',
    orderTotalAmount: 'Total amount (€)',
    discountAmountEuro: 'Discount amount (€)',
    discountAmountPercent: 'Discount amount (%)',
    allToCouponBased: 'Transform all promotions in this campaign to coupon based',
    allToNonCouponBased: 'Transform all promotions in this campaign to non coupon based',
    selectPromotionType: 'Select promotion type',
    createPromo: {
      basic1: 'Promotion based on items list',
      basic2: 'Promotion based on cart total',
      special1: 'Special cumulative pack',
      totalDependent: 'Promotion with special accrual rules',
    },
    special: {
      panel: 'Special cumulitive pack',
      oneItem: 'One item',
      twoItems: 'Two items',
      threeAndMoreItems: 'Three and more items',
      error: 'Value should be greater or equal to {{value}}',
    },
    dynamic: 'Editable accrual rules',
    totalDependent: {
      panel: 'Accrual rules',
      accruingRules: 'Accrual rules',
      total: 'Minimum order amount',
      discount: 'Accrual value to the card',
      limit1: 'Limit 1',
      limit2: 'Limit 2',
      limit3: 'Limit 3',
      limit4: 'Limit 4',
      limit5: 'Limit 5',
      limit6: 'Limit 6',
    },
  },
  products: {
    products: 'Products',
    productDetails: 'Product details',
    plig: 'Plig',
    pligs: 'Pligs',
    eansCount: 'Number of EANs',
    ean: 'EAN',
    eans: 'EANs',
    referencePrices: 'Reference prices',
  },
  transactions: {
    transactions: 'Transactions',
    transactionDetails: 'Transaction details',
    transactionNumber: 'Transaction number',
    exportTransactions: 'Export transactions',
    article: 'Article',
    listOfArticles: 'List of articles',
    unitPrice: 'Unit price',
    accumulationOnLoyaltyAccount: 'Accumulation on loyalty account',
    balanceBeforeAfter: 'Balance before / after',
  },
  clients: {
    client: 'Client',
    clients: 'Clients',
    clientDetails: 'Client details',
    cardNumber: 'Card number',
    cardNumbers: 'Card numbers (separated by comma)',
    confirmed: 'Confirmed',
    accounts: 'Accounts',
    exportBestClients: 'Export 100 best customers for 3 month',
    revenue: 'Revenue',
    clientProfile: 'Client profile',
    gender: 'Gender',
    genderTypes: {
      mr: 'Mr',
      mrs: 'Mrs',
      ms: 'Ms',
      miss: 'Miss',
    },
    birthday: 'Birthday',
    clientEmailChanged:
      'The request to change the email has been saved. The customer will receive an email (at his new address) to confirm this change.',
    deactivationWarning:
      'Warning: If you disable the account, the customer will not be able to access the account, earn stamps and use rewards/offers anymore.',
    removePersonalDataWarning: 'Attention: deletion of personal data is irreversible!',
    loyaltyInfo: 'Loyalty info',
    loyaltyCard: 'Loyalty card',
    balance: 'Balance',
    balanceHistory: 'Balance history',
    adminEntry: 'Admin entry',
    editBalance: 'Edit balance',
    amountToAdd: 'Add/Remove cents to account',
    userEmailChange:
      'Customer has a web account. Confirmation email will be sent first to a new email address.',
    clientEmailChange: "Customer doesn't have a web account. Change will be applied immediately.",
    uploadCardsFile: 'Upload file with cards',
    uploadButton: 'Upload',
    createCardsRequestSent: 'Request to create cards sent',
    expiredBalanceButton: 'Count expired balance',
    expiredBalance: 'Expired balance for last year',
    subtractExpiredBonusesRequestSent: 'Request to subtract expired bonuses sent',
    subtractModal: {
      title: 'Subtract expired bonuses for the last year',
      confirmationQuestion: {
        all: 'Are you sure you want to reset the balance for {{lastYear}} year for all clients? This action should be performed only once a year.',
        oneStore:
          'Are you sure you want to reset the balance for {{lastYear}} year for clients of store with number {{storeNumber}}? This action should be performed only once a year.',
      },
      selectStore: 'Select store to exclude subtraction:',
    },
  },
  coupons: {
    coupons: 'Coupons',
    addCoupon: 'Add coupon',
    barcode: 'Barcode',
    isUsed: 'Is used',
    usableAt: 'Usable at',
    expiresAt: 'Expires at',
    couponUsing: 'Coupon using',
  },
  address: {
    number: {
      maxLength: 'House number must not exceed 20 characters',
    },
    street: {
      maxLength: 'Street name must not exceed 255 characters',
    },
    city: {
      maxLength: 'City must not exceed 50 characters',
    },
    country: {
      maxLength: 'Country must not exceed 50 characters',
    },
  },
};
