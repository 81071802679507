import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import EuroIcon from '@material-ui/icons/Euro';

const SubtractExpiredBonusesButton = memo(({ title, ...rest }) => {
  const { t } = useTranslation();
  return (
    <IconButton title={t('clients:subtractModal:title')} {...rest}>
      <EuroIcon />
    </IconButton>
  );
});

SubtractExpiredBonusesButton.propTypes = {
  ...IconButton.propTypes,
};
export default SubtractExpiredBonusesButton;
