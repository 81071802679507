import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import StarsIcon from '@material-ui/icons/Stars';
import EuroIcon from '@material-ui/icons/Euro';

import { useAction } from 'app/hooks';
import { actions as modalsActions, modalTypes } from 'ducks/modals';
import { actions as clientsActions, selectors as clientsSelectors } from 'ducks/clients';
import { List, Breadcrumbs } from 'components';
import Publish from '@material-ui/icons/Publish';
import ClientFilter from './ClientFilter';
import ConfirmedCell from './ConfirmedCell';
import AccountsCell from './AccountsCell';
import ClientActionsCell from './ClientActionsCell';
import { selectors as authSelectors } from '../../../ducks/auth';

const rowConfig = [
  {
    name: 'firstName',
    label: 'app:firstName',
    source: 'firstName',
    sortable: true,
  },
  {
    name: 'lastName',
    label: 'app:lastName',
    source: 'lastName',
    sortable: true,
  },
  {
    name: 'email',
    label: 'auth:email',
    render: (item) => item.user.email || item.email,
    sortable: true,
  },
  {
    name: 'confirmed',
    label: 'clients:confirmed',
    component: ConfirmedCell,
    sortable: true,
  },
  {
    name: 'cardNumber',
    label: 'clients:cardNumber',
    render: ({ cards }) => cards[0] && cards[0].number,
  },
  {
    name: 'accounts',
    label: 'clients:accounts',
    component: AccountsCell,
  },
  {
    name: 'actions',
    label: 'app:actions',
    component: ClientActionsCell,
  },
];

const ClientList = memo(() => {
  const { items, meta } = useSelector(clientsSelectors.clientList);
  const fetchClientList = useAction(clientsActions.fetchClientList);
  const showModal = useAction(modalsActions.showModal);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);

  const toolbarActions = useMemo(() => {
    return [
      {
        name: 'exportBestClients',
        label: 'clients:exportBestClients',
        icon: StarsIcon,
        confirm: false,
        callback: () => showModal(modalTypes.BEST_CLIENTS_MODAL),
      },
      {
        name: 'upload',
        label: 'clients:uploadCardsFile',
        icon: Publish,
        show: isSuperAdmin,
        confirm: false,
        callback: () => showModal(modalTypes.UPLOAD_CARDS_MODAL),
      },
      {
        name: 'subtract',
        icon: EuroIcon,
        show: isSuperAdmin,
        confirm: false,
        callback: () => showModal(modalTypes.SUBTRACT_EXPIRED_BONUSES_MODAL),
      },
    ];
  }, [showModal]);

  return (
    <>
      <Breadcrumbs />
      <List
        data={items}
        meta={meta}
        onFetchData={fetchClientList}
        filterComponent={ClientFilter}
        rowConfig={rowConfig}
        toolbarActions={toolbarActions}
        fetchWithoutFilter={false}
      />
    </>
  );
});

export default ClientList;
