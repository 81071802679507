import React, { memo } from 'react';

import Modal from 'components/modals/Modal';
import { Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useAction } from '../../../app/hooks';
import { actions as clientsActions } from '../../../ducks/clients';

const SubtractExpiredBonusesForm = memo(({ handleSubmit, modalProps }) => {
  const { t } = useTranslation();
  const lastYear = new Date().getFullYear() - 1;
  const { number } = modalProps;

  return (
    <Modal titleText="clients:subtractModal:title" onOk={handleSubmit} {...modalProps}>
      <Form>
        <DialogContentText>
          {t('clients:subtractModal:confirmationQuestion:oneStore', {
            lastYear,
            storeNumber: number,
          })}
        </DialogContentText>
      </Form>
    </Modal>
  );
});

SubtractExpiredBonusesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
  number: PropTypes.string,
};

SubtractExpiredBonusesForm.defaultProps = {
  number: 'number',
};

const EnhancedSubtractExpiredBonusesForm = withFormik({
  enableReinitialize: true,
  validateOnBlur: true,
  mapPropsToValues: (props) => {
    const {
      modalProps: { number },
    } = props;
    return {
      storeNumbers: [number],
    };
  },
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(SubtractExpiredBonusesForm);

EnhancedSubtractExpiredBonusesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const SubtractExpiredBonusesModal = memo((props) => {
  const subtractExpiredBonuses = useAction(clientsActions.subtractExpiredBonuses);

  return (
    <EnhancedSubtractExpiredBonusesForm modalProps={props} onSubmit={subtractExpiredBonuses} />
  );
});

SubtractExpiredBonusesModal.propTypes = {
  ...Modal.propTypes,
};

export default SubtractExpiredBonusesModal;
