import React, { memo, useCallback, useState } from 'react';

import Modal from 'components/modals/Modal';
import { Form, useFormikContext, withFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import { useAction } from '../../../app/hooks';
import { actions as clientsActions } from '../../../ducks/clients';
import { StoreNumberActField } from '../../stores';
import { AddButton, RemoveButton } from '../../shared';
import useStyles from './SubtractExpiredBonusesModal.styles';

const SubtractExpiredBonusesForm = memo(({ handleSubmit, modalProps, storeNumbersToSkip }) => {
  const { t } = useTranslation();
  const lastYear = new Date().getFullYear() - 1;

  const [newStore, setNewStore] = useState({});
  const { values, setFieldValue } = useFormikContext();
  const stores = values[storeNumbersToSkip] ?? [];
  const classes = useStyles();

  const handleStoreChange = useCallback(
    (store) => {
      setNewStore((prev) => ({
        ...prev,
        store,
      }));
    },
    [setNewStore],
  );

  const addStore = useCallback(() => {
    const currentStore = newStore;

    if (currentStore && !stores.includes(currentStore.store)) {
      setFieldValue(storeNumbersToSkip, [...stores, currentStore.store]);
    }
  }, [setFieldValue, stores, newStore]);

  const removeStore = useCallback(
    (store) => {
      setFieldValue(storeNumbersToSkip, [...stores.filter((item) => item !== store)]);
    },
    [setFieldValue, stores],
  );

  const renderStore = useCallback((storeNumber) => {
    return (
      <div className={classes.eanRow} key={storeNumber}>
        {storeNumber}
        <RemoveButton
          className={classes.removeButton}
          onClick={() => removeStore(storeNumber)}
          size="small"
        />
      </div>
    );
  });

  return (
    <Modal titleText="clients:subtractModal:title" onOk={handleSubmit} {...modalProps}>
      <Form>
        <DialogContentText>
          {t('clients:subtractModal:confirmationQuestion:all', { lastYear })}
        </DialogContentText>
        <div>
          <DialogContentText>{t('clients:subtractModal:selectStore')}</DialogContentText>
          <Grid container>
            <Grid item xs={5}>
              <StoreNumberActField onChange={(store) => handleStoreChange(store)} />
            </Grid>
            <Grid item xs={2}>
              <AddButton className={classes.addButton} onClick={() => addStore()} />
            </Grid>
          </Grid>
          {stores && stores.map((s) => renderStore(s))}
        </div>
      </Form>
    </Modal>
  );
});

SubtractExpiredBonusesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  modalProps: PropTypes.shape(Modal.propTypes).isRequired,
  storeNumbersToSkip: PropTypes.string,
};

SubtractExpiredBonusesForm.defaultProps = {
  storeNumbersToSkip: 'storeNumbersToSkip',
};

const EnhancedSubtractExpiredBonusesForm = withFormik({
  enableReinitialize: true,
  validateOnBlur: true,
  mapPropsToValues: () => ({
    storeNumbersToSkip: [],
  }),
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
})(SubtractExpiredBonusesForm);

EnhancedSubtractExpiredBonusesForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const SubtractExpiredBonusesModal = memo((props) => {
  const subtractExpiredBonuses = useAction(clientsActions.subtractExpiredBonuses);

  return (
    <EnhancedSubtractExpiredBonusesForm modalProps={props} onSubmit={subtractExpiredBonuses} />
  );
});

SubtractExpiredBonusesModal.propTypes = {
  ...Modal.propTypes,
};

export default SubtractExpiredBonusesModal;
